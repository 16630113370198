.alert {
    position: fixed;
    z-index: 15;
    top: -400px;
    transition: 0.5s;
    width: 100%;
    padding: 32px 0px;
    background-color: $light-red;
    border-radius: 10px;

    // .alert__container

    &__container {
        display: flex;
        gap: 32px;
        @media(max-width:$md3){
            gap: 24px;
        }
    }

    &__svg{
        min-width: 28px;
    }
    // .alert__text

    &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: $title;
        @media(max-width:$md3){
            font-size: 14px;
            line-height: 19px;
        }
    }

    // .alert__close

    &__close {
    }
}

.alert_active{
    top: 0;
}



.thanks {
    padding: 100px 0px;
    position: fixed;
    z-index: 22;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $blackOpacity;
    justify-items: center;
    // .modal__container
    display: none;
    // .thanks__container

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // .thanks__block

    &__block {
        background-color: $white;
        border-radius: 10px;
        max-width: 640px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 63px;
        padding: 25px 29px;
        min-height: 290px;
        height: auto;
    }

    // .thanks__close

    &__close {
        display: flex;
        justify-content: right;
        cursor: pointer;
        transition: .3s;
        &:hover {
            svg {
                transition: .3s;
                transform: scale(1.2) rotate(180deg);
            }
        }
    }

    // .thanks__text

    &__text {
        text-align: center;
        font-weight: 500;
        font-size: 22px;
        line-height: 31px;
        color: $title;
        @media(max-width:$md3){
            font-weight: 400;
            font-size: 18px;
            line-height: 31px;
        }
    }
}

.thanks_active{
    display: grid;
}


.doctor-main-page{
    .breadcrumbs__container{
        margin-top: 40px;
    }
}

.main2 + .breadcrumbs__container{
    margin-bottom: -28px;
    margin-top: -25px;
    @media(max-width:$md2){
        margin-top: -15px;
        margin-bottom: -15px;
    }
}
.main + .breadcrumbs__container{
    margin-bottom: -28px;
    margin-top: -25px;
    @media(max-width:$md2){
        margin-top: -15px;
        margin-bottom: -15px;
    }
}
.breadcrumbs {

    &__container{
        margin-top: 30px;
        margin-bottom: -24px;
        @media(max-width:$md2){
            margin-top: 30px;
            margin-bottom: -10px;
        }
    }
    // .breadcrumbs__list

    &__list {
        display: flex;
        align-items: center;
        gap: 8px;
        @media(max-width:$md3){
            gap: 4px;
            align-items: flex-start;
        }
    }

    // .breadcrumbs__list-item

    &__list-item {
        svg{
            margin: 0px 7px;
        }
        @media(max-width:$md3){
            svg{
                height: 8px;
                margin: 0px 4px;
            }
        }
    }

    // .breadcrumbs__link

    &__link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        white-space: nowrap;
        color: #82879A;
        &:hover{
            color: $red;
        }
        @media(max-width:$md3){
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
        }
    }
}
.breadcrumbs-active {
    color: #000000;
    font-weight: 500;
    word-break: break-all !important;
    white-space: pre-wrap;
    &:hover{
        color: #000000;
    }
}

#surveyFormModalText{
    text-align: center;
    margin-top: 10px;
}




.service__block-list-with-img-list{
    max-width: 500px;
}
