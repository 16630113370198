// Fonts
// @import url(https://fonts.googleapis.com/css?family=Noto+Sans+Display:regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:300,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic&display=swap);
@import url(https://fonts.googleapis.com/css?family=Inter:300,regular,500,600,700,800,900&display=swap);

// Variables
@import 'variables';

@import 'null';
@import 'global';
@import 'detail';
@import 'main';
@import 'main2';
@import 'menu';

@import 'modal-window';

@import 'reviews';
@import 'form-reviews';
@import 'equipments';
@import 'doctors-main';
@import 'documents';
@import 'gallery';
@import 'news';
@import 'blog';
@import 'conditions';
@import 'prices';
@import 'contacts';
@import 'doctor';
@import 'service';
@import 'publication-blog';
@import 'news-publication';
@import 'components/header/header-bottom';
@import 'components/header/header-top';
@import 'components/header/header-mobile';
@import 'swipers/swiper-main';
@import 'components/about';
@import 'components/steps';
@import 'components/modal';
@import 'some-components';
@import 'components/price-c';
@import 'components/services';
@import 'components/action-prew';
@import 'components/advantages';
@import 'swipers/swiper-services';
@import 'swipers/news-swiper';
@import 'components/doctors';
@import 'components/response';
@import 'swipers/swiper-partners';
@import 'components/info';
@import 'components/answer';
@import 'components/soc';
@import 'components/mission';
@import 'profile/login';
@import 'profile/visit';
@import 'components/footer';
@import 'content-bloc';
@import 'pop-up';
@import 'static-page';
@import 'components/cookie';
@import 'components/popup-contact';
@import 'components/quote-third';
@import 'components/online-modal';
@import 'consultation';
@import 'survey';


.scroll-to {
    width: 0;
    height: 0;
    opacity: 0;
    transform: translateY(-100px);
}

.form-none {
    display: none;
}

.d-none {
    display: none !important;
}

.text-danger
{
    color: red;
}

.achor{
    position: relative;
    top: -200px;
    visibility: hidden;
    opacity: 0;
    height: 0;
}

[data-error] {
    color: red;
    font-size: 12px;
    position: relative;
    span {
        position: absolute;
        top: -240px;
    }
}
// Bootstrap
// @import '~bootstrap/scss/bootstrap';

// for sitemap

.sitemap {
    margin-top: 8rem;

    &__group {
        display: flex;
        flex-direction: column;
        gap: 14px;
        padding-left: 30px;
        position: relative;
        margin-top: 32px;
        padding-top: 16px;
        padding-bottom: 16px;

        &::before {
            border-radius: 10px;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 50px;
            height: 100%;
            background-color: $light-red;
            opacity: 0.6;
            z-index: -1;
        }
    }

    &__sub-title {
        font-size: 32px;
        font-weight: 500;
        color: $title;
    }

    &__links {
        margin-left: 70px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 36px;
        row-gap: 16px;
        @media(max-width:$md2) {
            grid-template-columns: 1fr;
            margin-left: 50px;
        }
    }

    a {
        color: $subtitle;
    }

    // @media(max-width:$md3)
}


.answer__block-title{
    color: #272B38 !important;
    font-size: 18px !important;
    line-height: 150% !important;
    font-weight: 400 !important;
}

//  set filters

.set-filter {
    display: none;
    width: 100vw;
    position: sticky;
    bottom: 0;
    // transform: translateX(-15px);
    margin: 0 auto;
    padding: 15px;
    background-color: $white;
    box-shadow: 0px -6px 10px rgba(44, 68, 142, 0.06);
    justify-content: center;
    align-items: center;

    &.translate-rigth {
        transform: translateX(-15px);
    }

    .set-filter__btn {
        width: 100%;
        max-width: 400px;
    }

    @media screen and (max-width:$md3) {
        display: flex;
    }
}


.content-bloc{
    div{
        &.first-div{
            margin-top: 0;
            &:first-child{
                ul,ol,div,p,h2,h1,h3,h4,h5,h6,span{
                    &:first-child{
                        margin-top: 0 !important;
                    }
                }
                .prices-content-description-grey{
                    margin-top: -16px !important;
                }
            }
            .first-first-div{
                ul,ol,div,p,h2,h1,h3,h4,h5,h6,span{
                    &:first-child{
                        margin-top: 0 !important;
                    }
                }
                div{
                    ul,ol,div,p,h2,h1,h3,h4,h5,h6,span{
                        &:first-child{
                            margin-top: 0 !important;
                        }
                    }
                    .prices-content-description-grey{
                        margin-top: -16px !important;
                    }
                }
                .prices-content-description-grey{
                    margin-top: -16px !important;
                }
            }
        }
    }
    .prices-content-description-grey{
        color: #82879A !important;
        font-size: 14px !important;
        line-height: 130% !important;
        margin-bottom: 24px;
        margin-top: -16px;
        *{
            color: #82879A !important;
            font-size: 14px !important;
            line-height: 130% !important;
        }
    }
    .prices__list{
        margin-top: 0px !important;
    }
    
}
.prices__list-description{
    ul , ol {
        display: flex;
        flex-direction: column;
        gap: 8px;
        li {
            list-style: inside !important;
            color: $subtitle !important;
            font-size: 14px !important;
            line-height: 130% !important;
        }
    }
    p{
        color: $subtitle !important;
        font-size: 14px !important;
        line-height: 130% !important;
    }
    
    // ol {
    //     display: flex;
    //     flex-direction: column;
    //     gap: 8px;
    //     li {
    //         list-style: inside !important;
    //         color: $subtitle !important;
    //         font-size: 16px !important;
    //         line-height: 130% !important;
    //     }
    // }
}
.prices-content-description-grey{
    color: #82879A !important;
    font-size: 14px !important;
    line-height: 130% !important;
    margin-bottom: 24px;
    margin-top: -16px;
    *{
        color: #82879A !important;
        font-size: 14px !important;
        line-height: 130% !important;
    }
}
.head-price-name{
    span{
        font-size: 14px !important
    }
}

.without-margin-top{
    margin-top:0 !important
}


// *{
//     outline: 1px solid blue;
// }
