.menu {
    max-width: 600px;
    width: 100%;
    @media(max-width:$md2){
        height: 100%;
        width: calc(100% + 48px);
        display: flex;
        flex-direction: column;
        // justify-content: space-between;;
        // transform: translateY(-37px);
    }

}

.menu-overlay{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    display: none;
    &.active-overlay-menu{
        display: block;
    }
}
.menu-top {
    max-width: 600px;
    width: 100%;
    @media(max-width:$md2){
        position: relative;
        // overflow: hidden;
    }
}
.menu-list {
    transition: 0.3s;
    @media(max-width:$md2){
        transition: 0.0s;
        .menu-ul {
            padding: 16px 30px 0 30px !important;
            align-items: flex-start !important;
            display: flex;
            flex-direction: column;
            gap: 16px;
            height: max-content;
        }
    }
    &.first-list {
        background: transparent;
        .menu-ul {
            display: flex;
            gap: 15px;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            .menu-item{
                align-items: center;
                color: #272b38;
                font-size: 18px;
                font-weight: 500;
                line-height: 140%;
            }
        }
        @media(max-width:$md2){
            height: 100%;
            overflow: hidden;
            .menu-ul{
                flex-direction: column;
            }
        }
    }
    &.second-list {
        .menu-head{
            justify-content: flex-start !important;
            align-items: center;
            color: #272b38;
            // display: flex;
            font-size: 18px;
            font-weight: 500;
            gap: 15px;
            line-height: 140%;

        }
        background: transparent;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        z-index: 2;
        left:0;
        top: 15px;
        max-width: 400px;
        width: max-content;
        min-width: 190px;
        padding-top: 24px;
        // max-height: 50vh;
        // overflow-y: auto;
        // overflow-x: visible;
        .menu-ul{
            border-radius: 10px;
            box-shadow: 0px 6px 20px 0px rgba(44, 68, 142, 0.06);
            background: $white;
            padding:16px 0;
            display: flex;
            max-height: 40vh;
            overflow-y: auto;
            overflow-x: hidden;
            flex-direction: column;
            gap: 12px;
            &::-webkit-scrollbar {
                background: #D9D9D9;
                border-radius: 5px;
                width: 5px;
            }
        
            &::-webkit-scrollbar-thumb {
                background: $red;
                border-radius: 5px;
            }
            .menu-item{
                padding: 0 16px;
                a , p{
                    font-size: 16px;
                    line-height: 140%;
                    color: $text3;
                }
                p{
                    width: 100%;
                    justify-content: flex-start;
                    display: flex;
                    gap: 15px;
                    svg{
                        rotate: 0deg;
                    }
                }

                &:hover{
                    a,p{
                        color: $red;
                        svg{
                            path{
                                stroke: $red;
                            }
                        }
                    }
                    .menu-list {
                        &.third-list{
                            a,p{
                                color: $text3;
                                svg{
                                    path{
                                        stroke: $text3;
                                    }
                                }
                            }
                        }
                    }
                }
                .menu-list {
                    &.third-list{
                        .menu-item{
                            &:hover{
                                a,p{
                                    color: $red;
                                }
                            }
                        }
                    }
                }
            }
            .menu-list{
                &.third-list{
                    .menu-ul{
                        // top: 0;
                    }
                }
            }
        }
        @media(max-width:$md2){
            overflow: hidden;
            top: 0;
            max-width: 100%;
            width: 100%;
            height: 100%;
            background: $white;
            
            padding-top: 0;
            .menu-head{
                display: flex;
            }
            .menu-ul{
                max-height: 100dvh;
                overflow-y: auto;
                overflow-x: auto;
                box-shadow: 0px 0px 0px 0px $white;
                .menu-item{
                    padding: 0px;
                }
            }
            &.isOpen{
                visibility: visible !important;
                opacity: 1 !important;
            }
        }
    }
    &.third-list {
        transition: 0;
        border-radius: 10px;
        // box-shadow: 0px 6px 20px 0px rgba(44, 68, 142, 0.06);
        // background: $white;
        // padding:16px;
        position: absolute;
        z-index: 3;
        left:100%;
        padding-left: 15px;
        top: -16px;
        opacity: 0;
        visibility: hidden;
        max-width: 430px;
        min-width: 220px;
        width: max-content;
        .menu-head{
            justify-content: flex-start !important;
            align-items: center;
            color: #272b38;
            // display: flex;
            font-size: 18px;
            font-weight: 500;
            gap: 15px;
            line-height: 140%;
        }
        .menu-ul{
            border-radius: 10px;
            box-shadow: 0px 6px 20px 0px rgba(44, 68, 142, 0.06);
            background: $white;
            padding:16px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            .menu-item{
                &:hover{
                    a,p{
                        color: $red;
                    }
                }
            }
        }
        &.isOpen{
            visibility: visible !important;
            opacity: 1 !important;
        }
        @media(max-width:$md2){
            top: 0;
            max-width: 100%;
            width: 100%;
            height: 100%;
            background: $white;
            padding-left: 0;
            left: 0;
            .menu-head{
                display: flex;
            }
            .menu-ul{
                box-shadow: 0px 0px 0px 0px $white;
            }
        }
    }
}

.menu-list{
    &.second-list , &.third-list{
        .menu-ul{
            .menu-item{
                width: 100%;
                background: $white;
            }
        }
    }
}


.menu-list{
    &.first-list{
        .menu-ul{
            .menu-item{
                &:hover{
                    .menu-list{
                        &.second-list{
                            opacity: 1;
                            visibility: visible;
                            .menu-ul{
                                .menu-item{
                                    .menu-list{
                                        &.third-list{
                                            opacity: 0;
                                            visibility: hidden;
                                        }
                                    }
                                }
                            }
                            @media(max-width:$md2){
                                opacity: 0;
                                visibility: hidden;
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu-list{
    &.first-list{
        .menu-ul{
            .menu-item{
                .menu-list{
                    &.second-list{
                        .menu-ul{
                            .menu-item{
                                &:hover{
                                    .menu-list{
                                        &.third-list{
                                            opacity: 1;
                                            visibility: visible;
                                            @media(max-width:$md2){
                                                opacity: 0;
                                                visibility: hidden;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}




.menu-head {
    display: none;
    @media(max-width:$md2){
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 6px 20px rgba(44,68,142,.06);
        padding: 30px;
        position: relative;
        // margin-bottom: 16px;
    }
    button{
        background: transparent;
    }
}

.menu-close {
}
.menu-ul {
    @media(max-width:$md2){
        .menu-item{
            p{
                justify-content: flex-start;
                svg{
                    rotate: -90deg;
                }
            }
        }
    }
}
.menu-item {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;

    a{
        cursor: pointer;
        color: $title;
    }
    p{
        cursor: pointer;
        display: flex;
        gap: 8px;
        align-items: center;
        color: $title;
    }
    &:hover{
        a,p{
            color: $red;
            svg{
                path{
                    stroke: $red;
                }
            }
        }
        .menu-list{
            &.second-list , &.third-list{
                a,p{
                    color: $text3;
                    svg{
                        path{
                            stroke: $text3;
                        }
                    }
                }
            }
        }
    }
    &.active-menu-link{
        p,a{
            color: $red !important;
            svg{
                path{
                    stroke:$red !important
                }
            }
        }
    }
    .second-list{
        .menu-item{
            &.active-menu-link{
                .third-list{
                    p , a{
                        color:$text3 !important;
                        svg{
                            path{
                                stroke:$text3 !important
                            }
                        }
                    }
                }
            }
        }
    }
    @media(max-width:$md2){
        position: static;
        width: 100%;
        p{
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
        }
    }
}


.menu-info {
    display: none;
    @media(max-width:$md2){
        display: block;
        padding: 8px 30px;
        .navbar-locales {
            margin-top: 14px;
        }
    }
}
