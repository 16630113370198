.survey {

    // .survey__container

    &__container {
    }


    form{
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        padding: 60px 0;
    }

    // .survey__question

    &__question {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    // .survey__question-title

    &__question-title {
        font-weight: 500;
        font-size: 18px;
    }

    // .survey__question-answers

    &__question-answers {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 4px;
        label{
            display: flex;
            align-items: center;
            gap: 6px;

            input{
                width: 20px;
                min-width: 20px;
                height: 20px;
                accent-color: #AB332F;
            }
        }
    }

   
    .label-text {
    }
    .text {
    }
    .select {
    }
    .selectBtn {
    }
    .selectDropdown {
    }
    .option {
    }
    .reset-input {
    }
    .phone-mask {
        padding: 13px ;
    }
    .btn {
    }
}
.modal {
    .survey-modal__text{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }
    &.survey-step-two{
     .survey-modal__text{
        display: none;
     }
     .modal__form-survey{
        display: flex;
     }

    }
 }
