.online-modal {
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 9999999;
  width: 100%;
  display: none;
  min-height: 100dvh;
  max-height: 100dvh;
  background: rgba(0, 0, 0, 0.5);
  padding: 40px 15px;
  font-weight: 300;
  &.activeModal{
    display: flex;
  }
  .phone-mask {
    padding: 12px 15px !important;  
  }
  &__container {
    margin: auto;
    max-width: 690px;
    width: 100%;
    background: $white;
    border-radius: $defoultRadius;
    padding: 20px 20px 32px 20px;
  }
  .modal__form{
    padding: 0px !important;
    gap: 12px !important;
  }
  .qs-squares{
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
    .qs-day{
      width: 100% !important;
    }
    .qs-num{
      min-width: 48px;
      max-width: 48px;
      margin: 0 auto;
    }
    @media (max-width: 479px){
      .qs-day{
        padding: 0px !important;
      }
      .qs-num{
        min-width: 33px;
        max-width: 33px;
        margin: 0 auto;
      }
    }
  }
  &__head {
    position: relative;
    padding-top: 10px;
    text-align: center;
  }
  &__text {
    font-size: 14px;
    color:  $subtitle;
    margin:12px auto;
    max-width: 600px;
    line-height: 135%;
  }
  &__close {
    position: absolute;
    z-index: 2;
    top: 0px;
    right: 0px;
    background: transparent;
    transition: 0.3s;
    // &:hover{
    //   transform: rotate(90deg);
    // }
  }
  .modal__buttons{
    margin-top: 12px;
  }
  form{
    width: 100%;
  }
  .close-icon-modal{
      position: absolute;
      top: 15px;
      right: 15px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      &::before{
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 1px ;
          background: $title;
          rotate: 45deg;
      }
      &::after{
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 1px ;
          background: $title;
          rotate: -45deg;
      }
    @media(max-width:$md4){
      top: 0px;
      right: 0px;
    }
  }
  &__content {
    margin:20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  &__radios{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30px, 70px));
    gap: 15px;
    margin: 15px 0;
    @media(max-width:$md3){
      gap: 12px;
      grid-template-columns: repeat(auto-fit, minmax(30px, 50px));
    }
  }
  &__radio{
    input{
     display: none;
    }
    label{
      padding:12px;
      border: 1px solid $subtitle;
      border-radius: 10px;
      display: flex;
      align-items: center;
      position: relative;
      font-size: 16px;
      font-weight: 300;
      justify-content: center;
      cursor: pointer;
      transition: 0.3s;
      &:hover{
        background: $light-red;
        color: $red;
        border: 1px solid $white;
      }
      @media(max-width:$md3){
        font-size: 14px;
        padding: 8px;
      }
      span{
        position: absolute;
        top: -14px;
        right: -10px;
        width: 40px;
        height: 24px;
        font-size: 12px;
        border-radius: 16px;
        color: $red;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $red;
        pointer-events: none;
        @media(max-width:$md3){
          top: -9px;
          right: -10px;
          width: 35px;
          height: 18px;
        }
      }
    }
    input:checked + label{
      background: $light-red;
      color: $red;
      border: 1px solid $white;
    }
    input:disabled + label{
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &__datepicker-block{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    position: relative;
    background-color: transparent;
    input{
      background: $background !important;
      border-radius: 10px !important;
      pointer-events: none !important;
    }
    svg{
      display: none;
    }
  }

  &__datepicker , &__datepicker-new{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: transparent;
    padding: 16px 24px;
    font-size: 16px;
    font-weight: 300;
    z-index: 0;
    cursor: pointer;
    color: $title;
    &::placeholder {
        color: $subtitle;

    }
    
  }
  .qs-datepicker-container{
    position: static !important;
    width: 100% !important;
    margin-top: 0 !important;
    box-shadow: none !important;
    border-radius: 10px !important;
    .qs-hidden{
      display: none !important;
    }
  }
}
.online-modal
.online-modal
.onnline-modal__text {
}
.online-modal__close {
}
.online-modal__content {
}
.modal__mini-block {
}
.modal__input-title {
}
.text {
}
.modal__input-block {
}
.modal__input {
}
.modal__select-mask {
}
.modal__select {
}
.select {
}
.modal__selectBtn {
}
.selectBtn {
}
.modal__selectDropdown {
}
.selectDropdown {
}
.modal__select-option {
}
.option {
}
.reset-input {
}
.phone-mask {
}
.modal__datepicker-block {
}
.modal__datepicker {
}


.qs-event {
  &::after{
    content: '-10%' !important;
    position: absolute !important;
    top: -4px !important;
    bottom: auto !important;
    right: -10px !important;
    font-size: 12px !important;
    color: $white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $red !important;
    padding: 2px 8px !important;
    border-radius: 16px !important;
    background: $red;
    height: auto !important;
    width: auto !important;
  }

  @media(max-width:$md3){
    &::after{
      top: -2px !important;
      right: -6px !important;
      width: 20px !important;
      height: 20px !important;
      content: "%" !important;
      font-size: 10px !important;
      padding: 1px !important;

    }
  }
}

.qs-event {
  &.qs-active{
    &::after{
      border: 1px solid $red !important;
      color: $red !important;
      background: $white;
    }
  }

  &.qs-disabled{
    &::after{
      display: none !important;
    }
  }
}  
