

html{
    scroll-behavior: smooth;
}

body{
    position: relative;
    font-family: 'Noto Sans';
}




header{
    position: sticky;
    top: -80px;
    z-index: 3;
    @media(max-width:$md2){
        position: fixed;
        top: 0;
        width: 100%;
    }
}
main{
    display: flex;
    flex-direction: column;
    gap: 80px;
    @media(max-width:$md2){
        padding-top: 125px;
        gap: 60px;
    }
}
.container{
    margin: 0 auto;
    padding: 0 24px;
    max-width: 1262px;
    width: 100%;
    @media(max-width:360px){
        padding: 0 15px;
    }
}
.btn{
    max-width: 280px;
    width: 100%;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 16px 30px;
    background: $red;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    // border: 2px solid $red;
    color: $white;
    transition: .5s;
    &:hover{
        background-color: $hover-red;
        // color: $red;
        transition: .5s;
    }
    &:disabled{
        pointer-events: none;
        opacity: 0.7;
    }
    @media(max-width:$md4){
        max-width: 350px;
    }
    @media(max-width:360px){
        padding: 10px 20px !important;
    }
    &.hidden-desc{
        @media(min-width:$md2){
            display: none !important;
        }
    }
}
.btn-2{
    width: 100%;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    background: $light-red;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    border: 2px solid $light-red;
    color: $red;
    transition: .5s;
    &:hover{
        background-color: #EDC9C9;;
        transition: .5s;
    }
}

.logo{
    img{
        min-width: 140px;
        min-height: 40px;
        max-width: 218px;
        // width: 100%;
        max-height: 60px;
        height: 100%;
    }
}

.location{
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 14px;
    line-height: 19px;
    color: $text3;
    &:hover{
        p{
            color: $red;
            transition: 0.2s;
        }
        svg{
            path{
                stroke: $red;
                transition: 0.2s;
            }
        }
    }
}

.social{
    min-height: 40px;
    min-width: 40px;
    border-radius: 100%;
    max-height: 40px;
    max-width: 40px;
    transition: 0.2s;
    &:hover{
        transform: scale(1.2);
        transition: 0.2s;
    }
}

.h1 ,h1 {
    font-weight: 700;
    font-size: 38px;
    line-height: 110%;
    color: $title;
    @media(max-width:$md3){
        font-size: 20px;
    }
}


.h2{
    font-weight: 600;
    font-size: 30px;
    line-height: 110%;
    @media(max-width:$md3){
        font-size: 18px;
    }
}

.h3{
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 120% !important;
    color: $title !important;
    @media(max-width:$md3){
        font-size: 18px !important;
    }
}
.h4{
    color: $title !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 130% !important;
    @media(max-width:$md3){
        // font-weight: 400;
        font-size: 16px !important;
    }
}

.service-title{
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: $title;
    @media(max-width:$md3){
        font-size: 20px;
    }
}
h2{
    position: relative;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: $title;
    @media(max-width:$md3){
        font-size: 20px;
    }
}

h3{
    margin: 0;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 120% !important;
    color: $title !important;
    @media(max-width:$md3){
        font-size: 18px !important;
    }
}

h4{
    margin: 0;
    color: $title !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 130% !important;
    @media(max-width:$md3){
        // font-weight: 400;
        font-size: 16px !important;
    }
}

h5{
    color: $title !important;
    line-height: 130% !important;
}

h6{
    color: $title !important;
    line-height: 130% !important;
}

.text{
    font-size: 16px;
    line-height: 150%;
}

.small-text{
    font-size: 14px;
    line-height: 150%;
}

.cart-title{
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    color: $title;
}

.cart-text{
    margin-top: 16px;
    font-family: 'Inter';
    font-size: 16px;
    line-height: 19px;
    color:$text3;
    @media(max-width:$md4){
        font-size: 14px;
        line-height: 17px;
    }

}

.disabled{
    cursor:not-allowed;
    border-color: $border !important;
    color: #BDC2D2 !important;
    svg{
        path{
            stroke: #BDC2D2 !important;
        }
    }
    &:hover{
        color:#BDC2D2  !important;
        svg{
            path{
                stroke: #BDC2D2 !important;
            }
        }
        background-color: transparent !important;

    }
}
.active-link{
    color: #AB332F;
}


.swiper-pagination {
}
.swiper-pagination-bullet {
    background-color: #ee2626 !important;
    max-width: 12px !important;
    max-height: 12px !important;
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
}
.swiper-pagination-bullet-active{
    background-color: $red !important;
}


.noScroll{
    overflow: hidden;
}

.headerFixed{
    position: fixed;
    z-index: 9;
    top: 0;
    width: 100vw;
}

.disabled-btn{
    background-color:$disabled;
}


.decline{
    display: flex;
    flex-direction: column;
    gap: 26px;

    li{
        .decline{
            li{
                &::before {
                    content: url(../img/decline2.svg) !important;
                    display: block !important;
                }
            }
        }
        .checkbox{
            list-style: none !important;
            li{
                &::before {
                    content: url(../img/list-style-2.svg) !important;
                    display: block !important;
                }
            }
        }
        ul{
            margin-top: 6px;
            gap: 10px;
            list-style:circle !important;
            list-style-position: outside !important;
            li{
                padding-left: 0px;
            }
            li::before{
                display: none !important;
                content: none !important;
            }
        }
        ol{
            gap: 10px;
            margin-top: 6px !important;
            list-style-position: inside !important;
            li{
                list-style:auto !important;
                padding-left: 0px;
            }
            li::before{
                display: none !important;
                content: none !important;
            }
        }
        margin-left: 40px !important;
        list-style: none !important;
        position: relative;
        // margin-left: 0px !important;
        &::before {
            content: url(../img/decline2.svg);
            display: block;
            position: absolute;
            left: -40px;
            top: 0%;
        }
    }
    @media(max-width:$md3){
        gap: 16px !important;
    }

}

.checkbox{
    display: flex;
    flex-direction: column;
    gap: 26px !important;
    li{
        margin-left: 40px !important;
        list-style: none !important;
        position: relative;
        // margin-left: 0px !important;
        &::before {
            content: url(../img/list-style-2.svg);
            display: block;
            position: absolute;
            left: -40px;
            top: 0%;
        }
        .decline{
            li{
                &::before {
                    content: url(../img/decline2.svg) !important;
                    display: block !important;
                }
            }
        }
        .checkbox{
            list-style: none !important;
            li{
                &::before {
                    content: url(../img/list-style-2.svg) !important;
                    display: block !important;
                }
            }
        }
        ul{
            gap: 10px !important;
            li{
                list-style:auto !important;
                list-style-position: outside !important;
                padding-left: 0px;
            }
            li::before{
                display: none !important;
                content: none !important;
            }
        }
        ol{
            gap: 10px !important;
            list-style:auto !important;
            list-style-position: inside !important;
            li{
                list-style:auto !important;
                list-style-position: inside !important;
                padding-left: 0px;
                ul{
                    li{
                        // font-weight: 700 !important;
                        list-style: outside !important;
                        display: list-item !important;
                        &::before{
                            display: none;
                            content: none;
                        }
                    }
                    margin-bottom: 14px;
                }
            }
            li::before{
                display: none !important;
                content: none !important;
            }
        }
    }
    @media(max-width:$md3){
        gap: 16px !important;
    }
}

ul{
    li{
        ol{
            list-style:auto !important;
            list-style-position: inside !important;
            margin-top: 6px !important;
            gap: 10px;
            li{
                padding-left: 0px;
            }
            li::before{
                display: none !important;
                content: none !important;
            }
        }
        ul{
            gap: 10px !important;
            li{
                list-style:auto !important;
                list-style-position: outside !important;
                padding-left: 0px;
                &.header__menu-sublist-link{
                    list-style:  none !important;
                }
            }
            li::before{
                display: none !important;
                content: none !important;
            }
        }
    }
}


ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    display: flex;
    flex-direction: column;
    gap: 26px;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    // margin-left: 42px ;
    position: relative;
}
ol li {
    counter-increment: my-awesome-counter;
    padding-left: 60px;
    // display: block;
    position: relative;
    .decline{
        li{
            &::before {
                content: url(../img/decline2.svg) !important;
                display: block !important;
            }
        }
    }
    .checkbox{
        list-style: none !important;
        li{
            &::before {
                content: url(../img/list-style-2.svg) !important;
                display: block !important;
            }
        }
    }
    ul{
        margin-top: 6px !important;
        list-style:circle !important;
        list-style-position: outside !important;
        gap: 10px;
        li{
            padding-left: 0px;
        }
        li::before{
            display: none !important;
            content: none !important;
        }
    }
    ol{
        list-style:auto !important;
        list-style-position: inside !important;
        margin-top: 6px !important;
        gap: 10px;
        li{
            padding-left: 0px;
        }
        li::before{
            display: none !important;
            content: none !important;
        }
    }
    // display: flex;
    // align-items: flex-start !important;
}
ol li::before {
    // width: 60px;
    position: absolute;
    top: 0;
    left: 0;
    content: "0"  counter(my-awesome-counter);
    font-family: 'Inter';
    color: white;
    background-color: transparent;
    text-shadow: -1px -1px 0 #811916, 1px -1px 0 #811916, -1px 1px 0 #811916, 1px 1px 0 #811916;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    // margin-right: 24px;
    text-align: center;
}

ol{
    :nth-child(n+10){
        &::before{
            content:"" counter(my-awesome-counter) !important;
        }
    }
}


li{
    align-items: center;
    // display: flex;
    // gap: 17px;
    @media(max-width:$md3){
        align-items: flex-start;
    }
}

.main-about{
    .about__title{
        display: none;
    }
    .about__btn{
        display: none;
    }
}

.main-services{
    .services__btn{
        display: none;
    }
}


.with-custom-fields{
    .doctor__modal{
        margin-top:  0 !important;
    }

}



.modal-img {

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.3);
    display: none;
    padding: 60px 0px;
    // place-content: center;
    overflow: hidden;

    &.active-img{
        display: flex;
    }
    // .modal-img__content

    &__container{
        display: grid;
        place-content: center;
    }

    &__content {

        position: relative;
        // max-width: 1000px;
        // width: 100%;
        // height: 100%;
        padding: 15px;
        border-radius: 15px;
        background-color: $white;
        @media(max-width:$md2){
            padding: 15px;
        }
    }

    // .modal-img__img

    &__img {
        border-radius: 15px;
        width: 100%;
        height: 100%;
        max-height: 90vh;
        img{
            border-radius: 15px;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    // .modal-img__close

    &__close {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        position: absolute;
        top: -11px;
        right: -14px;
        padding: 5px;
        font-size: 30px;
        rotate: 45deg;
        transition: 0.3s;
        background: white;
        border-radius: 15px;
        &:hover{
            cursor: pointer;
            transform: scale(1.02);
        }
    }

}

.inits-img{
    cursor: pointer;
}


.online-consultation-btn-js , .main2__link.btn , .prices__link.btn{
    max-width: max-content !important;
    white-space: nowrap !important;
    svg{
        min-width:20px;
        width: 20px;
        height: 20px;
        path{
            stroke: $white !important;
        }
    }
    @media(max-width:$md2){
        white-space: normal !important;
    }
    @media(max-width:$md4){
        max-width: 350px !important;
    }
}