.consultation {

  padding: 20px 0;
  // .consultation__container

  &__container {

  }

  // .consultation__title

  &__title {
    text-align: center;
    margin-bottom: 20px;
  }

  // .consultation__subtitle

  &__subtitle {
   margin-bottom: 20px;
    display: none;
  }

  // .consultation__content

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  // .consultation__content-left

  &__content-left {
    // max-width: 577px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  // .consultation__content-item

  &__content-item {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  // .consultation__radios

  &__radios {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  // .consultation__radio

  &__radio {
    display: flex;
    align-items: center;
    gap: 10px;
    input{
      width: 24px;
      height: 24px;
      accent-color: $red;
    }
    label{
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 27px;
    }
  }

  // .consultation__doctor

  &__doctor {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    &.has-doctor{
      & > .btn{
        display: none;
      }
      .consultation__doctor-content{
        display: flex;
        flex-direction: column;
        gap: 13px;
      }
    }
  }

  // .consultation__doctor-content

  &__doctor-content {
    display: none;
    .c-doctor{
      input{
        display: none;
      }
    }
  }

  // .consultation__content-right

  &__content-right {

  }

  &__send-form{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  @media(min-width:$md2){
    &__subtitle {
      width: 100%;
      display: block;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      color: #000;
      text-align: center;
    }

    &__content{
      flex-direction: row;
      justify-content: space-between;
    }

    &__content-left{
      max-width: 577px;
    }
    &__content-right{
      max-width: 600px;
      width: 100%;
    }

    &__send-form{
      width: 100%;
      flex-direction: row;
      align-items: center;
    }
  }

  .modal {

    // .modal__form
  
    &__form {
      padding: 0;
      gap: 16px;
    }
  
    // .modal__mini-block
  
    &__mini-block {
    }
  
    // .modal__input-title
  
    &__input-title {
    }
  
    // .modal__input-block
  
    &__input-block {
    }
  
    // .modal__select
  
    &__select {
    }
  
    // .modal__selectBtn
  
    &__selectBtn {
    }
  
    // .modal__selectDropdown
  
    &__selectDropdown {
    }
  
    // .modal__select-option
  
    &__select-option {
    }
  
    // .modal__input
  
    &__input {
      &.phone-mask{
        padding: 12px;
      }
    }
  
    // .modal__select-mask
  
    &__select-mask {
    }
  
    // .modal__buttons
  
    &__buttons {
    }
  
    // .modal__btn
  
    &__btn {
    }
  
    // .modal__btn-close
  
    &__btn-close {
    }
  }


  .qs-datepicker-container{
    position: static !important;
    width: 100% !important;
    margin-top: 0 !important;
    box-shadow: none !important;
    border-radius: 10px !important;
    z-index: 1 !important;
    .qs-hidden{
      display: none !important;
    }
  }
}
.container {
}
.label-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $title;
  span{
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; 
    opacity: 0.7;
  }
}
.radio {

  // .radio__input

  &__input {
  }

  // .radio__label

  &__label {
  }
}
.text-ellipsis {
}
.btn-red {
}
.choose-other-doctor {
  color: #AB332F;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.8px; 
  text-decoration-line: underline;
  text-underline-offset: 3px;
  cursor: pointer;
}
.c-doctor {
  position: relative;
  display: grid;
  grid-template-columns:68px 1fr;
  gap: 8px 10px;
  border-radius: 10px;
  border: 1px solid #E3E6EE;
  background: #FFF;
  padding: 10px;

  // .c-doctor__img

  &__img {
    width: 68px;
    height: 68px;
    border-radius: 10px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  // .c-doctor__content

  &__content {
  }

  // .c-doctor__title

  &__title {
    color: $red;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.8px;
    display: flex;
    align-items: center;
    height: 100%;
  }

  // .c-doctor__subtitle

  &__subtitle {
    grid-column:1/3;
    color: #82879A;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }

  // .c-doctor__price

  &__price {
    grid-column:1/3;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.8px; 
    color: #000;
    span{
      color: $red;
    }
  }

  &__show-reviews{
    grid-column:1/3;
    font-size: 12px;
    text-decoration: underline;
    color: $subtitle;
    display: block;
    padding: 10px 10px 10px 0;
    background: transparent;
    opacity: 0.9; 
    margin-right: 15px;
    cursor: pointer;
    text-underline-offset: 2px;
  }

  input{
    position: absolute;
    bottom: 10px;
    right: 10px;
    accent-color: $red;
    width: 24px;
    height: 24px;
  }

  @media(min-width:$md2){
    grid-template-columns: 170px 1fr ;
    &__img{
      grid-row: 1/6;
      grid-column: 1/3;
      width: 170px;
      height: 100%;
    }

    &__title{
      grid-row: 1/2;
      grid-column: 2/3;
    }

    &__subtitle{
      grid-row: 2/3;
      grid-column: 2/3;
    }

    &__price{
      grid-row: 3/4;
      grid-column: 2/3;
    }

    &__show-reviews{
      grid-row: 4/5;
      grid-column: 2/3;
    }

    
  }
}

.text {
}
.select {
}
.selectBtn {
}
.selectDropdown {
}
.option {
}
.hidden {
}
.hidden-piker {
}
.online-modal {

  // .online-modal__datepicker-block

  &__datepicker-block {
  }

  // .online-modal__datepicker

  &__datepicker {
  }

  // .online-modal__radios

  &__radios {
  }
}
.radios-content-js {
}
.reset-input {
}
.phone-mask {
}
.btn {
}
.js-close-online-modal {
}





.c-modal {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  padding: 20px;

  &.active-modal{
    display: flex;
  }
  // .c-modal__wrapper

  &__wrapper {
    width: max-content;
    max-height: 90dvh;
    overflow: auto;
    background: #FFF;
    border-radius: 10px;
    padding: 20px 20px 32px 20px;
    position: relative;
  }

  // .c-modal__title

  &__title {
    
    p{
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: #000;
      text-align: center;
      margin-bottom: 20px;
    }
    .c-modal__radios{
      display: none;
    }
  }

  // .c-modal__close

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: transparent;

  }

  // .c-modal__content

  &__content {
    width: 100%;
  }

  // .c-modal__doctors

  &__doctors {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  // .c-modal__reviews

  &__reviews {
  }

  &.modalDoctors {
    .c-modal__wrapper{
      max-width: 1218px;
    }
  }

  &.modalReviews{
    .c-modal__wrapper{
      max-width: 1200px;
    }
  }

  &.show-error{
    .c-modal__wrapper{
      p{
        display: none;
      }
      .c-modal__radios{
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        & > div {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          gap: 20px;
        }
        p{
          display: inline;
        }
      }
      span{
        display: block;
        text-align: center;
        font-size: 22px;
        color: $red;
        padding: 30px 30px 20px 30px;
        font-weight: 500;
        cursor: pointer;
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
    .c-modal__wrapper{
      max-width: 500px;
    }
    .c-modal__content{
      display: none;
    }
  }

  .c-doctor{
    cursor: pointer;
  }

  .swiper-review-consultation{
    
  }

  .response__cart-content-text_pop-up{
    align-items: flex-start;
    gap: 12px !important;
  }
  .response__cart-info{
    height: auto;
    p{
      --webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }
  }
  .response__cart-text{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    overflow: hidden;
  }
  .reviews__date{
    &.reviews__date_for-slider{
      margin-top: 14px;
      justify-content: flex-start !important;
    }
  }
  .c-modal__buttons-pagination{
    width: 100%;
    background: $white;
    position: sticky;
    bottom: 0;
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    margin-top: 40px;
    justify-content: center;
  }
  .swiper-button-prev{
    position: relative !important;
    min-width: 64px;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
    width: 64px !important;
    height: 64px !important;
    border-radius: 100%;
    background-color: $background;
    display: flex;
    align-items: center;
    justify-content: center;
    left:0 ;
    top: 0;
    svg{
        rotate: 180deg;
    }
    &::after{
        content: none;
    }

  }

  .swiper-button-next{
      position: relative !important;
      min-width: 64px;
      max-width: 64px;
      min-height: 64px;
      max-height: 64px;
      width: 64px !important;
      height: 64px !important;
      border-radius: 100%;
      background-color: $background;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0 ;
      top: 0;
      &::after{
          content: none;
      }

  }

  @media(min-width:$md2){
    &__doctors{
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .response__cart-text{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 14;
      overflow: hidden;
    }
  }
}

.c-doctor {

  // .c-doctor__img

  &__img {
  }

  // .c-doctor__title

  &__title {
  }

  // .c-doctor__subtitle

  &__subtitle {
  }

  // .c-doctor__price

  &__price {
  }

  // .c-doctor__show-reviews

  &__show-reviews {
  }
}
.reviews {

  // .reviews__cart-doctor

  &__cart-doctor {
  }

  // .reviews__cart-text-service

  &__cart-text-service {
  }

  // .reviews__date

  &__date {

    // .reviews__date_for-slider

    &_for-slider {
    }
  }
}
.response {

  // .response__cart-content-text

  &__cart-content-text {

    // .response__cart-content-text_pop-up

    &_pop-up {
    }
  }

  // .response__cart-block

  &__cart-block {

    // .response__cart-block_content

    &_content {
    }

    // .response__cart-block_items

    &_items {
    }

    // .response__cart-block_pop-up

    &_pop-up {
    }
  }

  // .response__cart-info

  &__cart-info {
  }

  // .response__cart-author

  &__cart-author {
  }

  // .response__cart-links

  &__cart-links {
  }

  // .response__cart-rait

  &__cart-rait {
  }

  // .response__cart-text

  &__cart-text {
  }
}
.h4 {
}
.small-text {
}
.link {
}
.date {
}
